<template>
    <div>
        <div v-if="inputVal.type!='shipping' && inputVal.type!='virtual'">
            <template v-if="inputVal.multiple_stocks">
                <div v-for="product in inputVal.source_products">
                    <a href="#"
                       v-if="product.id"
                       @click="$root.$children[0].openModal('article-modal', {id: product.id})"
                    >{{ product.sku || '-' }}
                        <font-awesome-icon class="ml-1" icon="external-link-alt"/>
                    </a>
                    <div v-else>-</div>

                    <zw-select-group v-model="inputVal.stocks_ids[product.parent_id]"
                                     :options="getPositionStocks(product)"
                                     name="article_stock_id"
                                     disable-label
                                     @change="savePosition('stocks_ids',inputVal)"
                                     text-field="name"
                                     value-field="id"
                                     size="sm"
                                     :readonly="readOnly && !partialReadonly"
                    ></zw-select-group>
                </div>
            </template>
            <template v-else>
                <zw-select-group v-model="inputVal.article_stock_id"
                                 :options="getPositionStocks(inputVal)"
                                 name="article_stock_id"
                                 disable-label
                                 @change="savePosition('article_stock_id',inputVal)"
                                 text-field="name"
                                 value-field="id"
                                 size="sm"
                                 :readonly="readOnly && !partialReadonly"
                ></zw-select-group>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomizedProductDataColumn',
    props: {
        value: [Object],
        savePosition: [Function],
        readOnly: [Boolean],
        partialReadonly: [Boolean],
    },
    methods: {
        getPositionStocks(item) {
            let options = [{
                id: null,
                name: '-',
            }]
            item.stocks.forEach(stock => {
                options.push({
                    id: stock.id,
                    name: stock.id + ': ' + stock.stock_name + ' (' + (stock.items_cnt_avail || 0) + ')',
                    disabled: item.count > stock.items_cnt_avail
                })
            })

            return options
        },
    },
    computed: {
        inputVal: {
            get() {
                if (!this.value.stocks_ids) {
                    return {...this.value, stocks_ids: {}}
                }
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>